import { useAuth } from '@clerk/nextjs';
import { useGrowthBook } from '@growthbook/growthbook-react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

import { useTabFocus } from '@/hooks/useTabFocus';
import { useStore } from '@/models/root-store';
import { CHECK_DATA_VERSION_INTERVAL, LoadingState } from '@/utils/constants';
import { getClientDetails } from '@/utils/helpers';

import MSChatBot from '../ms-chatbot/MSChatBot';

export const AppLoader: React.FC = observer(() => {
  const {
    customerStore: { isLoggedIn, growthbookCustomerAttributes },
    productStore: { productState },
    cartStore: { syncCart, cart, resetDraftCart, draftCart },
    generalStore: { checkDataVersion, removeNotification, setChatReady },
  } = useStore();
  const hasFocus = useTabFocus();
  const growthbook = useGrowthBook();
  const { isSignedIn } = useAuth();

  const router = useRouter();
  const { mode } = router.query;
  const cartOpen = router?.query?.cartOpen;

  // reset filters on the below routes
  const routesThatDontResetFilter = ['menu', 'products'];

  const modeDifference = draftCart && draftCart.mode && draftCart.mode !== mode;
  if (router.isReady && !mode && modeDifference) {
    resetDraftCart();
  }

  useEffect(() => {
    if (cartOpen) {
      cart.openCart();
    }
  }, [cartOpen]);

  useEffect(() => {
    const handleLivechatReadyEvent = () => {
      setChatReady(true);
    };
    window.addEventListener('lcw:ready', handleLivechatReadyEvent);
    return () => {
      window.removeEventListener('lcw:ready', handleLivechatReadyEvent);
    };
  }, []);

  useEffect(() => {
    const currentRoute = router.pathname;
    const currentRouteParts = currentRoute.split('/');
    const currentRouteFirstPart = currentRouteParts[1];
    if (!currentRouteFirstPart) {
      return;
    }

    const isRouteThatDoesntResetFilter = routesThatDontResetFilter.includes(
      currentRouteFirstPart,
    );
    if (
      !isRouteThatDoesntResetFilter &&
      cart?.menuBuilderPlan?.selectedItemPosition
    ) {
      cart?.menuBuilderPlan?.selectedItemPosition.setScrollPosition(0);
    }
    removeNotification();
  }, [router.pathname]);

  useEffect(() => {
    if (isLoggedIn && isSignedIn && productState === LoadingState.DONE) {
      syncCart();
    }
  }, [isLoggedIn, isSignedIn, productState]);

  useEffect(() => {
    if (router.pathname !== '/energy-drinks') {
      const chatIcon = document.querySelector('#launcher');
      const notificationIcon = document.querySelector(
        '[title="Number of unread messages"]',
      );
      chatIcon?.classList.remove('hidden');
      notificationIcon?.classList.remove('hidden');
    }
  }, [router.asPath]);

  useEffect(() => {
    if (!growthbook) return;
    const newAttributes = {
      ...growthbook.getAttributes(),
      ...growthbookCustomerAttributes,
      ...getClientDetails(),
    };
    growthbook.setAttributes(newAttributes);
  }, [growthbook, growthbookCustomerAttributes]);

  useEffect(() => {
    if (hasFocus) {
      const interval = setInterval(() => {
        checkDataVersion();
      }, CHECK_DATA_VERSION_INTERVAL);

      return () => {
        clearInterval(interval);
      };
    }

    return () => {};
  }, [hasFocus]);

  return (
    <>
      <MSChatBot />
      <Script src="https://js.braintreegateway.com/web/3.84.0/js/client.min.js"></Script>
      <Script src="https://js.braintreegateway.com/web/3.84.0/js/apple-pay.min.js"></Script>
    </>
  );
});
