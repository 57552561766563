// @ts-nocheck 
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from "mobx-state-tree"

/**
 * Typescript enum
 */

export enum SortableProductFields {
  calories="calories",
carbs="carbs",
category="category",
createdAt="createdAt",
fats="fats",
hasSubProducts="hasSubProducts",
isNew="isNew",
likes="likes",
liveAt="liveAt",
name="name",
netPrice="netPrice",
promotionTotal="promotionTotal",
protein="protein",
ranking="ranking",
sku="sku",
slug="slug"
}

/**
* SortableProductFields
*/
export const SortableProductFieldsEnumType = types.enumeration("SortableProductFields", [
        "calories",
  "carbs",
  "category",
  "createdAt",
  "fats",
  "hasSubProducts",
  "isNew",
  "likes",
  "liveAt",
  "name",
  "netPrice",
  "promotionTotal",
  "protein",
  "ranking",
  "sku",
  "slug",
      ])
