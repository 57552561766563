/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import _, { capitalize } from 'lodash';

import { Frequency, ProductCategory } from '@/models/api';
import type { LineItem, OrderPlan } from '@/models/cart/order-plan';
import type { Customer } from '@/models/customer/customer';

import { Currency, LARGE_COUNTS, SINGLE_DECIMAL_THRESHOLD } from '../constants';
import { TrackingSource } from './tracker.constant';

dayjs.extend(utc);

export const objectParser = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};
export const convertTimestampToIso = (delivery: any) => {
  const { deliveryDate, timeSlot } = delivery;
  const startTime = timeSlot.split(/[-&]/)[0].trim();
  let hoursIn24HourFormat;
  if (startTime.toLowerCase() === 'midnight') {
    hoursIn24HourFormat = 0;
  } else {
    const [hours, period] = startTime.split('am');
    hoursIn24HourFormat =
      period === 'pm' ? parseInt(hours, 10) + 12 : parseInt(hours, 10);
  }

  const date = new Date(deliveryDate);
  date.setHours(hoursIn24HourFormat, 0, 0, 0);

  // Convert the Date object to an ISO 8601 date-time string
  const iso8601DateTime = date.toISOString();
  return iso8601DateTime;
};

export const formatLineItems = (lineItems: LineItem[]) => {
  return lineItems.map((item) => {
    return {
      sku: (item.sku && item.sku.sku) || item.sku,
      qty: item.qty,
    };
  });
};

export const formatOrderPlan = (plan: OrderPlan, type: string) => {
  const calorie = plan.attributes.find(
    (attr) => attr.name === 'calorie',
  )?.value;
  const gender = plan.attributes.find((attr) => attr.name === 'gender')?.value;
  const numberOfDays = plan.attributes.find(
    (attr) => attr.name === 'numberOfDays',
  )?.value;
  const goal =
    plan.attributes.find((attr) => attr.name === 'goal')?.value || 'wlp';
  const sku = `${gender}-${goal}-${numberOfDays}days`;
  return {
    sku,
    name: plan.name,
    type,
    items: formatLineItems(plan.lineItems),
    subTotal: plan.netPrice,
    currency: Currency.AUD,
    meals: plan.getCategoryCount(ProductCategory.MEALS),
    soups: plan.getCategoryCount(ProductCategory.SOUPS),
    breakfast: plan.getCategoryCount(ProductCategory.BREKKIE),
    snacks: plan.getCategoryCount(ProductCategory.SNACKS),
    drinks: plan.getCategoryCount(ProductCategory.DRINKS),
    calorieIntake: calorie,
  };
};

export const formatProductMarketingTags = (tags: any) => {
  return tags?.map((tag: any) => tag?.text);
};

export const formatLineItem = (product: any) => {
  const marketingTags =
    product?.attributes?.find(
      (attr: any) => attr.name === 'marketingTags' && !_.isEmpty(attr.value),
    )?.value || [];

  return {
    sku: product?.sku,
    category: product?.category,
    name: product?.name,
    price: product?.netPrice,
    image_url: product?.cms?.displayPageImages?.[0]?.url || '',
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${product.slug}`,
    tags: formatProductMarketingTags(marketingTags) || undefined,
    calories: product?.nutritionalInfo?.calories || 0,
    macros: {
      carbs: product?.nutritionalInfo?.carbs || 0,
      fat: product?.nutritionalInfo?.fats || 0,
      protein: product?.nutritionalInfo?.protein || 0,
    },
  };
};

export const formatProductType = (data: any, productListType?: boolean) => {
  const { product, lineItem } = data;
  const quantity = lineItem?.qty || 1;

  const marketingTags =
    product?.attributes?.find(
      (attr: any) => attr.name === 'marketingTags' && !_.isEmpty(attr.value),
    )?.value || [];
  return {
    sku: product?.sku,
    category: product?.category,
    name: product?.name,
    ...(productListType ? {} : { quantity }),
    price: product?.netPrice,
    image_url: product?.cms?.displayPageImages[0]?.url,
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${product?.slug}`,
    tags: formatProductMarketingTags(marketingTags) || undefined,
    calories: product?.nutritionalInfo?.calories,
    macros: {
      carbs: product?.nutritionalInfo?.carbs || undefined,
      fat: product?.nutritionalInfo?.fats || undefined,
      protein: product?.nutritionalInfo?.protein || undefined,
    },
  };
};

export const extractPlanItems = (orderPlan: any, productList: any) => {
  return _.map(orderPlan.lineItems, (lineItem) =>
    formatProductType({
      product: lineItem.product,
      lineItem,
      orderPlan,
      productList,
    }),
  );
};

export const formatProductTypesFromSubscriptionPlan = (
  plan: any,
  productList: any,
) => {
  return _.map(plan.lineItems, (lineItem) => {
    const product = _.find(productList, (p) => p.sku === lineItem.sku);
    return formatProductType({
      product,
      lineItem,
      orderPlan: plan,
      productList,
    });
  });
};

export const formatAddressType = (address: any) => {
  return {
    street: address.street || '',
    postal_code: address.postcode || '',
    city: address.suburb || '',
    state: address.state || '',
    country: 'AU',
  };
};

export const formatSubscription = (subscription: any, productList: any) => {
  const address = formatAddressType(subscription.delivery.deliveryAddress);
  const products = _.flatMap(subscription.plans, (plan) =>
    formatProductTypesFromSubscriptionPlan(plan, productList),
  );
  const date = convertTimestampToIso(subscription.delivery);

  const data = {
    delivery_datetime: date,
    delivery_timewindow: subscription.delivery.timeSlot,
    delivery_day: dayjs.utc(subscription.delivery.deliveryDate).format('dddd'),
    delivery_frequency: subscription.frequency || '',
    address,
    products,
  };
  return data;
};

export const formatFilters = (selectedFilters: Record<string, unknown>) => {
  const filters = _.flatten(
    _.map(_.entries(selectedFilters), ([key, values]: [string, unknown]) =>
      _.map(values as string[], (value) => {
        let parsedValue;
        try {
          parsedValue = JSON.parse(value.replace(/^"|"$/g, ''));
        } catch (e) {
          parsedValue = value.replace(/^"|"$/g, '');
        }
        return {
          type: key,
          value: Array.isArray(parsedValue)
            ? String(parsedValue[0])
            : typeof parsedValue === 'object'
              ? JSON.stringify(parsedValue)
              : String(parsedValue),
        };
      }),
    ),
  );

  return filters;
};

export const extractGAPlanItems = (plans: any) => {
  const items: {
    item_id: string;
    item_name: string;
    item_category: string;
    item_category2: string;
    quantity: number;
    price: number;
    discount: number;
  }[] = [];
  plans.forEach((plan: any, index: number) => {
    plan.lineItems.forEach((item: any, idx: number) => {
      const { product } = plans[index].lineItems[idx];
      let foo: any = {};
      if (product) {
        foo = {
          item_id: product.sku,
          item_name: product.name,
          item_category: product.category,
          item_category2: plan.name,
          quantity: item.qty,
          price: product.netPrice,
          discount: Math.abs(product.promotionTotal) || 0,
        };
      }

      items.push(foo);
    });
  });
  return items;
};

export const formatCartData = (cart: any, productsList?: any) => {
  const { cartId } = cart;
  const products = _.flatMap(cart.plans, (plan) =>
    extractPlanItems(plan, productsList),
  );

  return {
    cart_id: cartId,
    products,
  };
};

export const formatCheckoutData = (cart: any, productsList?: any) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { cart_id, products } = formatCartData(cart, productsList);
  const totalDiscount = cart.promotions.reduce(
    (total: any, promotion: { value: any }) => total + promotion.value,
    0,
  );
  const taxTotal = _.round((cart.total - cart.giftCertificateTotal) / 11, 2);

  return {
    cart_id,
    checkout_id: cart_id,
    affiliation: TrackingSource.Web,
    total: cart.total,
    shipping: cart.shippingTotal,
    tax: taxTotal,
    discount: totalDiscount || undefined,
    coupon: cart?.couponCode || undefined,
    currency: Currency.AUD,
    products,
  };
};

export const formatLineItemProduct = (productData: any) => {
  const marketingTags =
    productData?.attributes?.find(
      (attr: any) => attr.name === 'marketingTags' && !_.isEmpty(attr.value),
    )?.value || [];
  return {
    sku: productData.sku,
    name: productData.name,
    category: productData.category,
    price: productData.netPrice,
    image_url: productData.cms.displayPageImages[0].url,
    url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${productData.slug}`,
    tags: formatProductMarketingTags(marketingTags),
    calories: productData.nutritionalInfo.calories,
    macros: {
      carbs: productData.nutritionalInfo.carbs || undefined,
      fat: productData.nutritionalInfo.fats || undefined,
      protein: productData.nutritionalInfo.protein || undefined,
    },
    discount: productData.promotionTotal,
    coupon: productData.couponCode || undefined,
  };
};

export const formatProductData = (
  productData: any,
  location: string,
  cartId: string,
) => {
  const product = formatLineItemProduct(productData);
  return {
    cart_id: cartId,
    source: location,
    ...product,
  };
};

export function getEnumIndex(enumObj: any, value: string): number {
  const enumValues = Object.values(enumObj);
  return enumValues.indexOf(value);
}

export const formatOrderData = (
  order: any,
  productsList: any,
  cartId: string,
  is_returning: boolean,
  order_count: number,
) => {
  const coupon = order?.promotions[0]?.code || undefined;
  const products = _.flatMapDeep(order?.orderPlans, (plan) =>
    formatProductTypesFromSubscriptionPlan(plan, productsList),
  );

  const {
    orderTotal,
    taxTotal,
    shippingPromotionTotal,
    shippingTotal,
    orderPromotionTotal,
  } = order.totals;

  return {
    order_id: order?.invoiceNumber || '',
    cart_id: cartId || '',
    checkout_id: cartId || '',
    affiliation: TrackingSource.Web,
    total: orderTotal,
    shipping: shippingPromotionTotal - shippingTotal,
    discount: orderPromotionTotal || undefined,
    tax: taxTotal,
    coupon,
    currency: Currency.AUD,
    products,
    delivery_datetime: order.delivery?.deliveryDate,
    is_returning,
    order_count,
  };
};

export const formatOrderFailedData = (order: any, productsList: any) => {
  const products = _.flatMap(order.plans, (plan) =>
    extractPlanItems(plan, productsList),
  );
  const orderTotal = order.plans.reduce(
    (total: any, plan: any) => total + plan.netPrice,
    0,
  );
  const timestamp = convertTimestampToIso(order.delivery);
  const shippingTotal =
    order.frequency === Frequency.ONCE ? order.shippingTotal : 0;
  const discounts = order.promotions.reduce(
    (total: any, promotion: any) => total + promotion.value,
    0,
  );
  const taxTotal = _.round(orderTotal / 11, 2);

  return {
    cart_id: order.cartId || '',
    affiliation: TrackingSource.Web,
    total: orderTotal,
    shipping: shippingTotal,
    disocunt: discounts,
    tax: taxTotal,
    coupon: order.couponCode || '',
    delivery_datetime: timestamp,
    currency: Currency.AUD,
    products,
  };
};

export const findValueById = (id: any, questions: any) => {
  return questions
    .flatMap((arr: any) => {
      return arr.questions.filter((obj: any) => obj.id === id);
    })
    .map((obj: any) => obj.question)[0];
};

export const formatCustomization = (data: any) => {
  const payload = {
    location: data.location === 'Upsell' ? 'Cart/upsell' : data.location,
    sku: data.lineItem.sku,
    name: data.lineItem.name,
    category: data.lineItem.category,
    subCategory: data.lineItem.displaySubCategory,
    originalPrice: data.lineItem.grossPrice,
    offerPrice: data.lineItem.netPrice,
    discount: data.lineItem.promotionTotal,
    currency: 'AUD',
  };
  return payload;
};

export const formatAccountPayload = (
  data: any,
  provider: string,
  customer?: Customer,
) => {
  const finalData = {
    // eslint-disable-next-line no-underscore-dangle
    mongo_id: customer ? customer._id : '',
    state: data.state,
    location: data.location,
    sso_option: provider,
  };
  return { event: data.event, data: finalData };
};

export const formatCataloguePayload = (data: any) => {
  const categoryCounts: any = {};
  data.products.forEach((product: any) => {
    const { category } = product;
    categoryCounts[category] = (categoryCounts[category] || 0) + 1;
  });

  function getActivity(filters: any) {
    if (filters.currentSelectedFilter === 'sort') {
      return 'Sort';
    }

    if (filters.currentSelectedFilter === 'filter') {
      return 'Filter';
    }

    return '';
  }

  function getSort(obj: any) {
    return `${obj.fieldName || obj[0].fieldName} (${
      obj.sortDirection || obj[0].sortDirection === 'ASC'
        ? 'Low to High'
        : 'High to Low' || ''
    })`;
  }

  function getActivityValue() {
    const { selectedFilters } = data.filters;
    selectedFilters.proteinType = selectedFilters.proteinType
      .map((item: any) => JSON.parse(item))
      .flat();

    const strValue = JSON.stringify(data.filters.selectedFilters).replace(
      /\\"/g,
      '',
    );
    return JSON.parse(strValue);
  }

  function getEvent(value: any) {
    if (value === 'Filter') {
      return 'catalogue_filter';
    }
    if (value === 'Sort') {
      return 'catalogue_sort';
    }
    return 'catalogue_view';
  }

  function getView(value: string): string {
    const view: any = {
      view: 'view',
      'swap-item': 'Swap',
      'add-item': 'Add',
      'add-extra': 'Extra',
    };

    return view[value];
  }

  function getFilterFeature() {
    const { isRangeOpen } = data.filters;
    if (isRangeOpen) {
      return 'chevron';
    }
    return 'standard';
  }

  const filterKeys: any = [];

  Object.keys(data.filters.selectedFilters).forEach((key: any) => {
    if (data.filters.selectedFilters[key].length > 0) {
      filterKeys.push(key);
    }
  });

  const finalData = {
    mealPlanType:
      getView(data.view) === 'view' ? 'Custom Meal Plan' : 'Goal Based Plan',
    catalogueView: capitalize(getView(data.view)),
    catalogueState: filterKeys.length === 0 ? 'All' : 'Subset',
    activity: getActivity(data.filters),
    activityValue: [getActivityValue()],
    listCount: [categoryCounts],
    filters: filterKeys,
    sort: getSort(JSON.parse(data.filters.sort)),
  };

  return {
    event: getEvent(getActivity(data.filters)),
    filter_type: getFilterFeature(),
    data: finalData,
  };
};

export const formatDiscountCode = (
  event: any,
  code: any,
  type?: string,
  error?: any,
) => {
  const finalData = {
    event,
    data: {
      code,
      type: type || 'default',
      error_message: error || '',
    },
  };
  return finalData;
};

export const formatLikeCount = (num: number): string => {
  const LARGE_COUNT = _.findLast(
    LARGE_COUNTS,
    ({ threshold }) => num >= threshold,
  );
  const unit = LARGE_COUNT?.unit ?? '';
  const threshold = LARGE_COUNT?.threshold || 1;
  const precision = num >= SINGLE_DECIMAL_THRESHOLD ? 0 : 1;
  const value = (num / threshold).toFixed(precision);
  return value.endsWith('.0')
    ? `${value.slice(0, -2)}${unit}`
    : `${value}${unit}`;
};

export const formatObjectFields = (
  obj: Record<string, any>,
  fields: string[],
  transformFn: (value: any, key: string) => any,
) => {
  _.each(obj, (value, key) => {
    if (fields.includes(key)) {
      obj[key] = transformFn(value, key);
    } else if (_.isObject(value)) {
      // use recursion for nested objects and arrays
      formatObjectFields(value, fields, transformFn);
    }
  });
};

export const formatPriceField = (value: any) => {
  if (_.isNumber(value)) {
    return value.toFixed(2);
  }
  return value;
};

export const formatPriceFields = (obj: Record<string, any>) => {
  const priceFields = ['grossPrice', 'netPrice', 'promotionTotal'];
  formatObjectFields(obj, priceFields, formatPriceField);
};
