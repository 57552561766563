import { gql } from 'graphql-request';

export const CUSTOMER_BASIC_INFO_SELECTOR = gql`
_id
role
firstName
lastName
email
`;

export const SUBSCRIPTION_SELECTOR = gql`
_id
category
chargeDate
endDate
frequency
startDate
status
subId
delivery {
  deliveryDate
  timeSlot
  deliveryAddress {
    businessName
    country
    name
    postcode
    state
    street
    suburb
  }
}
`;

export const SUBSCRIPTION_DELIVERY_UPDATE = gql`
_id
subId
delivery {
  deliveryDate
  timeSlot
}
`;

export const SUBSCRIPTION_DELIVERYDATES = gql`
name
postcode
deliveryDetails {
  date
  formattedDate
  isExpress
  timeslots
}
`;

export const SUBSCRIPTION_DELIVERY_CHECK = gql`
delivery {
  deliveryAddress {
    country
    name
    postcode
    state
    street
    suburb
    businessName
  }
}
chargeDate
status
category
`;

export const CUSTOMER_ADDRESS_SELECTOR = gql`
addressId
businessName
country
firstName
instructions
lastName
method
phone
postcode
preferred
state
street
suburb
message {
  message
  status
  title
  type
}
`;

export const AUTH_SELECTOR = gql`
accessToken
customerId
refreshToken
usid
customerInfo {
  _id
  role
  firstName
  lastName
  email
}
`;

export const UPDATE_CUSTOMER_EMAIL_SELECTOR = gql`
message
status
title
type
`;

export const CUSTOMER_PROFILE_SELECTOR = gql`
_id
email
productLikes
credit
profileForm
numberOfOrders
profileStage
isSubscriber
unsubscribeEmailStatus
referCode
attributes {
  name
  value
}
promotionalCampaign
addresses {
  addressId
  businessName
  country
  firstName
  instructions
  lastName
  method
  phone
  postcode
  preferred
  state
  street
  suburb
}
paymentMethods {
  bankRoutingNumber
  paymentCard {
    cardType
    creditCardExpired
    expirationMonth
    expirationYear
    holder
    maskedNumber
    numberLastDigits
  }
  paymentInstrumentId
  paymentMethodId
  paymentMethodToken
  paypalAccountEmail
  preferred
}
createdAt
daysSinceLastOrder
`;

export const MYORDERS_SELECTOR = gql`
_id
invoiceNumber
orderStatus
paymentStatus
processedAt
totals {
  totalPaid
  itemTotal
  orderTotal
  taxTotal
  grandTotal
  shippingTotal
  orderPromotionTotal
  shippingPromotionTotal
}
`;

export const ORDER_DETAILS_SELECTOR = gql`
_id
key
createdAt
invoiceNumber
orderStatus
paymentStatus
promotions {
  code
  promotionId
}
totals {
  totalPaid
  itemTotal
  orderTotal
  taxTotal
  grandTotal
  shippingTotal
  orderPromotionTotal
  shippingPromotionTotal
}
orderPlans {
  attributes {
    name
    value
  }
  category
  image
  name
  promotionTotal
  netPrice
  lineItems {
    qty
    sku
    name
    grossPrice
    promotionTotal
    category
    netPrice
    taxCategory
    attributes {
      name
      value
    }
    cms {
      tileImage {
        url
      }
    }
  }
}
billingAddress {
  businessName
  country
  name
  postcode
  state
  street
  suburb
}
transactions {
  account
  cardType
  amount
  type
  meta
}
delivery {
  deliveryAddress {
    postcode
    street
    suburb
    state
    name
  }
  deliveryDate
}
processedAt
giftCertificates {
  grossPrice
  netPrice
  recipientEmail
  recipientName
  senderName
}
`;

export const REQUEST_CUSTOMER_EMAIL_UPDATE = `
message 
`;

export const INITIAL_EMAIL_CHANGE = gql`
questions {
  label
  question
}
token
`;

export const REORDER_DETAILS_SELECTOR = gql`
message {
  message
  status
  title
  type
}
orderPlans {
  category
  grossPrice
  name
  netPrice
  planId
  image
  promotionTotal
  attributes {
    name
    value
  }
  lineItems {
    qty
    sku
    grossPrice
    promotionTotal
    category
    attributes {
      name
      value
    }
    cms {
      tileImage {
        url
      }
    }
  }
  subCategory
}
`;

export const CANCEL_REASONS_SELECTOR = gql`
   meta
`;

export const PROFILE_STATE_SELECTOR = gql`
name
title
visiblePathnames
invisiblePathnames
message
bannerTitle
bannerSubtitle
footer {
  value
  blocks
  links
}
sections {
  title
  subtitle
  isRequired
  questions {
    name
    method
    isMulti
    placeholder
    options
  }
}
`;
