// @ts-nocheck
/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */
import { types } from 'mobx-state-tree';

/**
 * Typescript enum
 */

export enum ProfileStageModelOrderBy {
  _createdAt_ASC = '_createdAt_ASC',
  _createdAt_DESC = '_createdAt_DESC',
  _firstPublishedAt_ASC = '_firstPublishedAt_ASC',
  _firstPublishedAt_DESC = '_firstPublishedAt_DESC',
  _isValid_ASC = '_isValid_ASC',
  _isValid_DESC = '_isValid_DESC',
  _publicationScheduledAt_ASC = '_publicationScheduledAt_ASC',
  _publicationScheduledAt_DESC = '_publicationScheduledAt_DESC',
  _publishedAt_ASC = '_publishedAt_ASC',
  _publishedAt_DESC = '_publishedAt_DESC',
  _status_ASC = '_status_ASC',
  _status_DESC = '_status_DESC',
  _unpublishingScheduledAt_ASC = '_unpublishingScheduledAt_ASC',
  _unpublishingScheduledAt_DESC = '_unpublishingScheduledAt_DESC',
  _updatedAt_ASC = '_updatedAt_ASC',
  _updatedAt_DESC = '_updatedAt_DESC',
  active_ASC = 'active_ASC',
  active_DESC = 'active_DESC',
  bannerSubtitle_ASC = 'bannerSubtitle_ASC',
  bannerSubtitle_DESC = 'bannerSubtitle_DESC',
  bannerTitle_ASC = 'bannerTitle_ASC',
  bannerTitle_DESC = 'bannerTitle_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  invisiblePathnames_ASC = 'invisiblePathnames_ASC',
  invisiblePathnames_DESC = 'invisiblePathnames_DESC',
  message_ASC = 'message_ASC',
  message_DESC = 'message_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  priority_ASC = 'priority_ASC',
  priority_DESC = 'priority_DESC',
  title_ASC = 'title_ASC',
  title_DESC = 'title_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  visiblePathnames_ASC = 'visiblePathnames_ASC',
  visiblePathnames_DESC = 'visiblePathnames_DESC',
}

/**
 * ProfileStageModelOrderBy
 */
export const ProfileStageModelOrderByEnumType = types.enumeration(
  'ProfileStageModelOrderBy',
  [
    '_createdAt_ASC',
    '_createdAt_DESC',
    '_firstPublishedAt_ASC',
    '_firstPublishedAt_DESC',
    '_isValid_ASC',
    '_isValid_DESC',
    '_publicationScheduledAt_ASC',
    '_publicationScheduledAt_DESC',
    '_publishedAt_ASC',
    '_publishedAt_DESC',
    '_status_ASC',
    '_status_DESC',
    '_unpublishingScheduledAt_ASC',
    '_unpublishingScheduledAt_DESC',
    '_updatedAt_ASC',
    '_updatedAt_DESC',
    'active_ASC',
    'active_DESC',
    'bannerSubtitle_ASC',
    'bannerSubtitle_DESC',
    'bannerTitle_ASC',
    'bannerTitle_DESC',
    'createdAt_ASC',
    'createdAt_DESC',
    'id_ASC',
    'id_DESC',
    'invisiblePathnames_ASC',
    'invisiblePathnames_DESC',
    'message_ASC',
    'message_DESC',
    'name_ASC',
    'name_DESC',
    'priority_ASC',
    'priority_DESC',
    'title_ASC',
    'title_DESC',
    'updatedAt_ASC',
    'updatedAt_DESC',
    'visiblePathnames_ASC',
    'visiblePathnames_DESC',
  ],
);
