import { gql } from 'graphql-request';

export const FILTER_SELECTOR = gql`
key
sections {
  id
  key
  name
  displayType
  filterType
  category {
    key
  }
  tips {
    blocks
    links
    value
  }
  values {
    id
    name
    showCount
    key
    value
    data
    restrictedToLoggedIn
    singleKey
    multiKey
    icon {
      url
    }
    iconActive {
      url
    }
    subValues {
      id
      name
      showCount
      key
      value
      data
      singleKey
      multiKey
    }
  }
}`;

export const SORT_SELECTOR = gql`
  key
  sortOption {
    id
    name
    data
}`;

export const NEW_SORT_SELECTOR = gql`
  id
  name
  data
`;

export const SLUG_SELECTOR = gql`
slug
`;

export const PRODUCT_PDP_SELECTOR = gql`
_id
name
likes
slug
category
displayCategory
displaySubCategory
sku
status
visible
netPrice
grossPrice
promotionTotal
hasSubProducts
isBulkDiscountable
recommendations {
    sku
}
subProducts {
  sku
  qty
  product {
    slug
    cms {
      productInstruction {
        instructions {
          title
          text(markdown: true)
        }
      }
    }
    nutritionalInfo {
      size
      unit
      calories
      carbs
      fats
      protein
      ingredients
      allergens
      servingSize
      servingsPerPackage
      panelInfo {
        display
        name
        per100
        formattedPer100
        perServing
        formattedPerServing
        unit
        children {
          display
          name
          per100
          formattedPer100
          perServing
          formattedPerServing
          unit
        }
      }
    }
  }
}
attributes {
  name
  value
}
cms {
  id
  primaryName
  secondaryName
  displayCategory {
    name
  }
  displaySubcategory {
    name
  }
  defaultBreadcrumb {
    key
    name
    pagePaths {
      path
    }
  }
  orderQuantityLimit
  slug
  seoTitle
  seoDescription
  ogImage {
    url(imgixParams: $imgixParams)
  }
  tileImage {
    url(imgixParams: $imgixParams)
  }
  displayPageImages {
    url(imgixParams: $imgixParams)
  }
  productInstruction {
    instructions {
      title
      text(markdown: true)
    }
  }
  description {
    blocks
    links
    value
  }
  dietaryTags {
    text
    icon {
      url
    } 
  }
  tags {
    text
    icon {
      url
    }
  }
  quantityTag
  primaryDisplayTag {
    text
    fontColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
  secondaryDisplayTag {
    text
    fontFamily
    fontWeight
    fontColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
  tertiaryDisplayTag {
    text
    fontColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
  displaySubcategory {
    key
    name
  }
  menuHierarchy {
    key
    sections {
      __typename
      ... on ProductBenefitSectionRecord {
        list {
          icon {
            alt
            url
            width
            height
            title
          }
          title
          description
        }
      }
      ... on RangeDescriptionSectionRecord {
        description {
          value
          links
          blocks
        }
        title
        titleBackgroundColor {
          hex
        }
        buttonText
        buttonLink
      }
    }
  }
}
nutritionalInfo {
  size
  unit
  calories
  carbs
  fats
  protein
  ingredients
  allergens
  servingSize
  servingsPerPackage
  spiceRating
  panelInfo {
    display
    name
    per100
    formattedPer100
    perServing
    formattedPerServing
    unit
    children {
      display
      name
      per100
      formattedPer100
      perServing
      formattedPerServing
      unit
    }
  }
}
`;

export const PRODUCT_LIKE_SELECTOR = gql`
_id
sku
likes
`;

export const PRODUCT_SELECTOR = gql`
_id
likes
name
slug
category
displayCategory
displaySubCategory
sku
status
netPrice
grossPrice
promotionTotal
hasSubProducts
isBulkDiscountable
subProducts {
  sku
  qty
}
liveAt
attributes {
  name
  value
}
cms {
  id
  orderQuantityLimit
  tileImage {
    url(imgixParams: $imgixParams)
  }
  displayPageImages {
    url(imgixParams: $imgixParams)
  }
  dietaryTags {
    text
    icon {
      url
    } 
  }
  tags {
    text
    icon {
      url
    }
  }
  primaryDisplayTag {
    text
    fontColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
  secondaryDisplayTag {
    text
    fontColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
  tertiaryDisplayTag {
    text
    fontColor {
      hex
    }
    backgroundColor {
      hex
    }
  }
  displaySubcategory {
    key
    name
  }
  displayCategory {
    key
    name
  }
}
nutritionalInfo {
  size
  unit
  calories
  carbs
  fats
  protein
  allergens
}
`;

export const PRODUCT_SELECTOR_NEW = gql`
_id
likes
name
slug
category
displayCategory
displaySubCategory
sku
status
netPrice
grossPrice
promotionTotal
hasSubProducts
isBulkDiscountable
subProducts {
  sku
  qty
}
liveAt
attributes {
  name
  value
}
nutritionalInfo {
  size
  unit
  calories
  carbs
  fats
  protein
  allergens
}
`;

export const PRODUCT_CARD_SELECTOR = gql`
_id
name
slug
sku
status
netPrice
grossPrice
promotionTotal
attributes {
  name
  value
}
nutritionalInfo {
  size
  unit
  calories
  carbs
  fats
  protein
  allergens
}`;

export const CMS_PRODUCT_SELECTOR = gql`
primaryName
tileImage {
  url(imgixParams: $imgixParams)
}
sku
id
orderQuantityLimit
displayPageImages {
  url(imgixParams: $imgixParams)
}
dietaryTags {
  text
  icon {
    url
  } 
}
tags {
  text
  icon {
    url
  }
}
quantityTag
primaryDisplayTag {
  text
  fontColor {
    hex
  }
  backgroundColor {
    hex
  }
}
secondaryDisplayTag {
  text
  fontFamily
  fontWeight
  fontColor {
    hex
  }
  backgroundColor {
    hex
  }
}
tertiaryDisplayTag {
  text
  fontColor {
    hex
  }
  backgroundColor {
    hex
  }
}
displaySubcategory {
  key
  name
}
displayCategory {
  key
  name
}
`;

export const PRODUCT_CARD_CMS_SELECTOR = gql`
primaryName
tileImage {
  url(imgixParams: $imgixParams)
}
sku
id
displayName
displayPageImages {
  url(imgixParams: $imgixParams)
}
primaryDisplayTag {
  text
  fontColor {
    hex
  }
  backgroundColor {
    hex
  }
}
`;

export const PRIMARY_TAG_SELECTOR = gql`
id
text
fontColor {
  hex
}
backgroundColor {
  hex
}
`;

export const PRODUCT_CATEGORY_SELECTOR = gql`
key
name
subcategories {
  key
  name
  visible
  description {
    blocks
    links
    value
  }
  extraLabel
}
categoryDescription
categoryImage {
  url(imgixParams: $imgixParams)
}
`;

export const PRODUCT_SKU_SELECTOR = gql`
_id
sku
`;

export const DELIVERY_INFO_SELECTOR = gql`
name
postcode
state
fee
zoneCode
deliveryDetails {
  date
  formattedDate
  timeslots
  isExpress
}
cutoffTime
cms {
  subscriptionInstruction {
    thresholdDescription {
      threshold
    }
  }
}
`;

export const PLACE_ORDER_SELECTOR = gql`
  invoiceNumber
  key
  frequency
`;

export const UPSELLS_SELECTOR = gql`
  upsells
  extraBoxFee
`;

export const ORDER_SELECTOR = gql`
key
category
createdAt
customer {
  email
  firstName
  lastName
  phone
}
frequency
invoiceNumber
shippingStatus
delivery {
  deliveryAddress {
    businessName
    country
    name
    postcode
    state
    street
    suburb
  }
  deliveryDate
  instructions
  timeSlot
  trackingNumber
}
orderPlans {
  lineItems {
    attributes {
      name
      value
    }
    qty
    sku
    name
    netPrice
    cms {
      tileImage {
        url
      }
    }
  }
  image
  netPrice
  name
  category
  attributes {
    name
    value
  }
}
billingAddress {
  businessName
  country
  name
  postcode
  state
  street
  suburb
}
transactions {
  type
  amount
  account
}
totals {
  grandTotal
  itemTotal
  orderPromotionTotal
  orderTotal
  shippingPromotionTotal
  shippingTotal
  taxTotal
  totalPaid
}
giftCertificates {
  lineitemText
  netPrice
  recipientEmail
  recipientName
  senderName
}
`;

export const GIFT_CARD_SELECTOR = gql`
balance
amount
enabled
status
`;

export const PROMO_CODE_SELECTOR = gql`
discounts {
  code
  desc
  id
  type
  value
  calloutMsg
}
shippingDiscount {
  code
  desc
  id
  type
  value
}
shippingTotal
credit
`;

export const PAYMENT_CLIENT_TOKEN_SELECTOR = gql`
token
`;

export const PRODUCT_RESTRICTION_LIMITS_SELECTOR = gql`
products {
  sku
}
quantityLimit
errorMessage
`;

export const VERIFY_RECAPTCHA_TOKEN_SELECTOR = gql`
status
`;

export const PROMO_BANNER_SELECTOR = gql`
    key
    id
    code
    isnewcustomer
    banners {
      updatedAt
      titleType
      title
      textColor {
        hex
      }
      key
      id
      description {
        value
        links
        blocks
      }
      borderColor {
        hex
      }
      backgroundColor {
        hex
      }
    
  }`;

export const APP_CONTENT_SELECTOR = gql`
meta
`;

export const UPDATE_ORDER_DELIVERY_DATE = gql`
status
message {
  message
}
order {
  delivery {
    deliveryDate
    timeSlot
  }
}`;

export const APP_TIP_SELECTOR = gql`
key
tip
`;

export const UI_CONTENT_SELECTOR = gql`
key
content
`;

export const MEGA_NAV_SELECTOR = gql`
links {
  text
  accessibilityText
  subLinks {
    text
    accessibilityText
    externalUrl
    relativePathLink
  }
  relativePathLink
  linkType
  externalUrl
  fullMenu
  mobileOnly
  extraLinks {
    icon {
      url
    }
    onlyShowIcon
    relativePathLink
    text
    externalUrl
    backgroundColour {
      hex
    }
    textColor {
      hex
    }
    onlyForLoggedIn
  }
  discoveryBanner {
    text
    link
    bgcolor {
      hex
    }
    mobileImage {
      url
      alt
    }
    desktopImage {
      url
      alt
    }
  }
}
`;

export const PRODUCT_RECOMMENDATION_SELECTOR = gql`
  tileTitle
`;
