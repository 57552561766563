import _ from 'lodash';

import {
  FILTER_SELECTOR,
  MEGA_NAV_SELECTOR,
  NEW_SORT_SELECTOR,
} from '@/graphql/selectors';
import {
  CMS_SEO_SELECTOR,
  GOAL_BASED_QUIZ_SELECTOR,
  MENU_CATEGORY_SELECTOR,
} from '@/graphql/selectors/cms';
import {
  DYNAMIC_LANDING_PAGE_SELECTOR,
  LANDING_PAGE_V2_SELECTOR,
  MENU_V2_SELECTOR,
} from '@/graphql/selectors/landingPage';
import type {
  DynamicLandingPageContentRecordModelType,
  FilterRecordModelType,
  GoalBasedQuizRecordModelType,
  LandingPageV2RecordModelType,
  MenuHierarchyRecordModelType,
  MenuSortOptionRecordModelType,
  NavbarRecordModelType,
  ProductCategoryRecordModelType,
  SortMenuRecordModelType,
} from '@/models/api';
import type { RootStoreType } from '@/models/root-store';
import type { SeoData } from '@/shared/types';
import {
  filterActiveMenuHierarchies,
  populateHierarchySlug,
} from '@/utils/helpers';

export type MenuBuilderData = {
  allFiltersCms: FilterRecordModelType[];
  sortMenuCms: SortMenuRecordModelType;
  allProductCategoriesCms: ProductCategoryRecordModelType[];
  landingPageV2Cms: LandingPageV2RecordModelType;
};

export type MenuBuilderDataV2 = {
  filterCmsData: FilterRecordModelType;
  sortMenuCms: MenuSortOptionRecordModelType[];
  allMenuHierarchiesCms: MenuHierarchyRecordModelType[];
  landingPageV2Cms: LandingPageV2RecordModelType;
};
export const getMenuBuilderDataV2 = async (
  store: RootStoreType,
): Promise<MenuBuilderDataV2> => {
  const { filterCms }: { filterCms: FilterRecordModelType } =
    await store.api.queryFilterCms(
      {
        filter: {
          key: {
            eq: 'website-main-v2',
          },
        },
      },
      FILTER_SELECTOR,
    );

  const {
    allMenuSortOptionsCms,
  }: { allMenuSortOptionsCms: MenuSortOptionRecordModelType[] } =
    await store.api.queryAllMenuSortOptionsCms({}, NEW_SORT_SELECTOR);

  const {
    allMenuHierarchiesCms,
  }: { allMenuHierarchiesCms: MenuHierarchyRecordModelType[] } =
    await store.api.queryAllMenuHierarchiesCms(
      {
        filter: {
          category: {
            eq: 'category',
          },
          active: {
            eq: true,
          },
        },
      },
      MENU_CATEGORY_SELECTOR,
    );

  const {
    landingPageV2Cms,
  }: { landingPageV2Cms: LandingPageV2RecordModelType } =
    await store.api.queryLandingPageV2Cms(
      {
        filter: { slug: { eq: 'menu' } },
      },
      MENU_V2_SELECTOR,
    );

  return {
    filterCmsData: filterCms,
    sortMenuCms: allMenuSortOptionsCms,
    allMenuHierarchiesCms,
    landingPageV2Cms,
  };
};

export const getMegaNavCategories = async (
  store: RootStoreType,
): Promise<MenuHierarchyRecordModelType[]> => {
  const { allMenuHierarchiesCms } = await store.api.queryAllMenuHierarchiesCms(
    {
      filter: {
        category: {
          eq: 'category',
        },
        active: {
          eq: true,
        },
      },
    },
    MENU_CATEGORY_SELECTOR,
  );

  // Filter out Liked, Specials and New
  let categories = _.filter(
    allMenuHierarchiesCms,
    (category) => category?.childrenCategories?.length > 0,
  );

  // Filter out inactive categories
  categories = filterActiveMenuHierarchies(categories);
  populateHierarchySlug(categories);

  return categories;
};

export const getGeneralPageSeoData = async (
  store: RootStoreType,
  slug: string,
): Promise<SeoData> => {
  const { generalPageCms } = await store.api.queryGeneralPageCms(
    {
      filter: {
        slug: {
          eq: slug,
        },
      },
    },
    CMS_SEO_SELECTOR,
  );
  return generalPageCms;
};

export const getGoalBasedQuizQuestions = async (
  store: RootStoreType,
  key: string,
): Promise<GoalBasedQuizRecordModelType> => {
  const { goalBasedQuizCms } = await store.api.queryGoalBasedQuizCms(
    {
      filter: {
        key: {
          eq: key,
        },
      },
    },
    GOAL_BASED_QUIZ_SELECTOR,
  );
  return goalBasedQuizCms;
};

export const getLandingPageVariation = async (
  store: RootStoreType,
  campaign: string,
  slug: string,
  content: string,
): Promise<DynamicLandingPageContentRecordModelType | null> => {
  const { dynamicLandingPageCampaignCms } =
    await store.api.queryDynamicLandingPageCampaignCms(
      {
        filter: { campaign: { eq: campaign } },
      },
      DYNAMIC_LANDING_PAGE_SELECTOR,
    );

  if (!dynamicLandingPageCampaignCms) {
    return null;
  }

  const { landingPageVariations } = dynamicLandingPageCampaignCms;
  const matchingVariations = landingPageVariations.filter(
    (variation: { slug: string }) => variation.slug === slug,
  );

  const foundVariation = matchingVariations.find(
    (variation: { content: string }) => variation.content === content,
  );

  if (foundVariation) {
    return foundVariation;
  }

  const defaultVariation = matchingVariations.find(
    (variation: { content: string }) => variation.content === 'default',
  );

  return defaultVariation;
};

export const getLandingPageV2SeoData = async (
  store: RootStoreType,
  slug: string,
): Promise<SeoData> => {
  const { landingPageV2Cms } = await store.api.queryLandingPageV2Cms(
    {
      filter: {
        slug: {
          eq: slug,
        },
      },
    },
    LANDING_PAGE_V2_SELECTOR,
  );
  return landingPageV2Cms;
};

export const getNavbarData = async (
  store: RootStoreType,
): Promise<NavbarRecordModelType> => {
  const { navbarCms } = await store.api.queryNavbarCms({}, MEGA_NAV_SELECTOR);
  return navbarCms;
};
