import Script from 'next/script';
import { useEffect } from 'react';

const MSChatBot: React.FC = () => {
  useEffect(() => {
    if (!window) return;
    window.lcw = () => {
      return {
        webChatContainerProps: {
          webChatStyles: {
            backgroundColor: 'White',
            bubbleBackground: 'rgba(244,246,248,255)',
            bubbleBorderRadius: 0,
            bubbleTextColor: 'rgba(68,68,69,255)',
            bubbleBorderColor: 'rgba(255,0,53,1)',
            bubbleFromUserBorderRadius: 50,
            bubbleFromUserBackground: 'rgba(244,246,248,255)',
            bubbleFromUserTextColor: 'rgba(68,68,69,255)',
            botAvatarBackgroundColor: 'rgba(255,0,53,1)',
            suggestedActionTextColor: 'rgba(255,0,53,1)',
            suggestedActionBorderRadius: 40,
            suggestedActionBorderColor: 'rgba(255,0,53,1)',
            suggestedActionHeight: 30,
            suggestedActionLayout: 'stacked',
          },
        },
        footerProps: {
          controlProps: {
            hideEmailTranscriptButton: true,
            hideAudioNotificationButton: true,
          },
        },
      };
    };
  }, []);

  return (
    <>
      <Script
        id="Microsoft_Omnichannel_LCWidget"
        src="https://oc-cdn-public-oce.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js"
        data-app-id={process.env.NEXT_PUBLIC_MS_CHAT_APP_ID}
        data-org-id={process.env.NEXT_PUBLIC_MS_CHAT_ORG_ID}
        data-org-url={process.env.NEXT_PUBLIC_MS_CHAT_ORG_URL}
        data-hide-minimize-button="true"
        data-font-family-override="Arial"
        data-lcw-version="prod"
        data-customization-callback="lcw"
        data-hide-chat-button="true"
      ></Script>
    </>
  );
};

export default MSChatBot;
